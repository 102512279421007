import { httpGet, httpPost, httpDelete } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    clientRequests: [],
    clientCount: 0,
    clientQuota: 0,
    clients: null,
  }),
  mutations: {
    RESET_REQUEST(state) {
      state.clientRequests = [];
      state.clientCount = 0;
      state.clientQuota = 0;
      state.clients = null;
    },
    SET_REQUESTS(state, requests) {
      state.clientRequests = requests;
    },
    SET_COUNT(state, count) {
      state.clientCount = count;
    },
    SET_QUOTA(state, quota) {
      state.clientQuota = quota;
    },
    REMOVE_REQUEST(state, userId) {
      const index = state.clientRequests.findIndex((r) => r.athleteId === userId);
      if (index > -1) state.clientRequests.splice(index, 1);
    },
    SET_CLIENTS(state, clients) {
      state.clients = clients;
      state.clientCount = clients.length;
    },
    DELETE_CLIENT(state, userId) {
      const index = state.clients.findIndex((client) => client.userId === userId);
      if (index > -1) {
        state.clients.splice(index, 1);
        state.clientCount = Math.max(0, state.clientCount - 1);
      }
    },
  },
  actions: {
    resetRequest({ commit }) {
      commit('RESET_REQUEST');
    },
    async fetchRequests({ commit }) {
      const res = await httpGet('/coaching/requests');
      const { requests, clientCount, clientQuota } = res.data;
      commit('SET_REQUESTS', requests);
      commit('SET_COUNT', clientCount);
      commit('SET_QUOTA', clientQuota);
    },
    async acceptRequest({ commit }, userId) {
      const res = await httpPost(`/coaching/accept/${userId}`);
      const { clientCount, clientQuota } = res.data;
      commit('REMOVE_REQUEST', userId);
      commit('SET_COUNT', clientCount);
      commit('SET_QUOTA', clientQuota);
    },
    async rejectRequest({ commit }, userId) {
      const res = await httpPost(`/coaching/reject/${userId}`);
      const { clientCount, clientQuota } = res.data;
      commit('REMOVE_REQUEST', userId);
      commit('SET_COUNT', clientCount);
      commit('SET_QUOTA', clientQuota);
    },
    async fetchClients({ commit }) {
      const res = await httpGet('/coaching/clients');
      commit('SET_CLIENTS', res.data);
    },
    async deleteClient({ commit }, userId) {
      await httpDelete(`/coaching/client/${userId}`);
      commit('DELETE_CLIENT', userId);
    },
  },
};
