import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowsAlt,
  faTrashAlt,
  faCheck,
  faTimes,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faSyncAlt,
  faPlus,
  faEdit,
  faBell,
  faPaperPlane,
  faThList,
  faEllipsisH,
  faInfoCircle,
  faNewspaper,
  faUtensils,
  faCalendarWeek,
  faMinus,
  faFileSignature,
  faUserCircle,
  faSearch,
  faHistory,
  faPen,
  faPaste,
  faWindowRestore,
  faVideo,
  faFilm,
  faCaretUp,
  faCaretDown,
  faQuestionCircle,
  faPlay,
  faPlayCircle,
  faUsers,
  faBook,
  faFolder,
  faFolderOpen,
  faLink,
  faUnlink,
  faChartLine,
  faChartPie,
  faBars,
  faSort,
  faEnvelope,
  faDownload,
  faLevelUpAlt,
  faCreditCard,
  faCircleCheck,
  faUserPlus,
  faAddressBook,
  faMugHot,
} from '@fortawesome/free-solid-svg-icons';

import {
  faYoutube,
  faVimeo,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowsAlt,
  faTrashAlt,
  faCheck,
  faTimes,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faSyncAlt,
  faPlus,
  faEdit,
  faBell,
  faPaperPlane,
  faThList,
  faEllipsisH,
  faInfoCircle,
  faNewspaper,
  faUtensils,
  faCalendarWeek,
  faMinus,
  faFileSignature,
  faUserCircle,
  faSearch,
  faHistory,
  faPen,
  faPaste,
  faWindowRestore,
  faVideo,
  faFilm,
  faCaretUp,
  faCaretDown,
  faQuestionCircle,
  faPlay,
  faPlayCircle,
  faUsers,
  faBook,
  faFolder,
  faFolderOpen,
  faLink,
  faUnlink,
  faChartLine,
  faChartPie,
  faBars,
  faSort,
  faEnvelope,
  faDownload,
  faLevelUpAlt,
  faCreditCard,
  faCircleCheck,
  faUserPlus,
  faAddressBook,
  faMugHot,
);

library.add(
  faYoutube,
  faVimeo,
);

Vue.component('fa-icon', FontAwesomeIcon);
