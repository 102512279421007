import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import { firebaseAuth } from '@/core/firebase';

Vue.use(VueRouter);

const appTitle = process.env.VUE_APP_TITLE;

const routes = [
  {
    path: '/index.html',
    component: Home,
    meta: {
      title: appTitle,
      requiresAuth: true,
    },
  },
  {
    path: '/webhook',
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: appTitle,
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: {
      title: `Register - ${appTitle}`,
    },
    // eslint-disable-next-line consistent-return
    beforeEnter: async (to, from, next) => {
      if (firebaseAuth.currentUser) {
        return next('/');
      }
      next();
    },
  },
  {
    path: '/register/:invitationCode',
    name: 'registerByInvitationCode',
    component: () => import('../views/Register.vue'),
    meta: {
      title: `Register - ${appTitle}`,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: `Login - ${appTitle}`,
    },
    // eslint-disable-next-line consistent-return
    beforeEnter: async (to, from, next) => {
      if (firebaseAuth.currentUser) {
        return next('/');
      }
      next();
    },
  },
  {
    path: '/lost-password',
    name: 'lostPassword',
    component: () => import('../views/LostPassword.vue'),
    meta: {
      title: `Lost password - ${appTitle}`,
    },
  },
  {
    path: '/donation',
    name: 'donation',
    component: () => import('../views/Donation.vue'),
    meta: {
      title: `Donation - ${appTitle}`,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue'),
    meta: {
      title: `Account - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('../views/Notification.vue'),
    meta: {
      title: `Notification - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/nutrition',
    name: 'nutrition',
    component: () => import('../views/Nutrition.vue'),
    meta: {
      title: `Nutrition - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/metric',
    name: 'metric',
    component: () => import('../views/Metric.vue'),
    meta: {
      title: `Metric - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/program/:programId',
    name: 'program',
    component: () => import('../views/Program.vue'),
    meta: {
      title: `Program - ${appTitle}`,
      requiresAuth: true,
    },
  },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: () => import('../views/Articles.vue'),
  //   meta: {
  //     title: `Articles - ${appTitle}`,
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      title: `Settings - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/Clients.vue'),
    meta: {
      title: `Clients - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import('../views/Activate.vue'),
    meta: {
      title: `Activate - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/programs',
    name: 'programs',
    component: () => import('../views/Programs.vue'),
    meta: {
      title: `Programs - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('../views/Library.vue'),
    meta: {
      title: `Library - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/client/:clientId',
    name: 'client',
    component: () => import('../views/Client.vue'),
    meta: {
      title: `Client - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/workout/:sessionId',
    name: 'workout',
    component: () => import('../views/Workout.vue'),
    meta: {
      title: `Workout - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/w/:sessionId/:clientId?',
    name: 'workoutSummary',
    component: () => import('../views/WorkoutSummary.vue'),
    meta: {
      title: `Workout summary - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/coaching/survey/:coachId/:athleteId',
    name: 'coachingSurvey',
    component: () => import('../views/CoachingSurvey.vue'),
    meta: {
      title: `Coaching survey - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/coaching/invitation/:invitationCode',
    name: 'coachingInvitation',
    component: () => import('../views/CoachingInvitation.vue'),
    meta: {
      title: `Coaching invitation - ${appTitle}`,
      requiresAuth: true,
    },
  },
  {
    path: '/s/checkout',
    name: 'paySubscription',
    component: () => import('../views/SubscriptionCheckout.vue'),
    meta: {
      title: `Subscription checkout - ${appTitle}`,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  // Set page title.
  document.title = to.meta.title || appTitle;

  // Reset scroll.
  window.scrollTo(0, 0);

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !firebaseAuth.currentUser) {
    const redir = to.path === '/' ? '' : `#${encodeURIComponent(to.path)}`;
    next(`/login${redir}`);
  } else {
    next();
  }
});

export default router;
