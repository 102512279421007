import '@/scss/app.scss';

import Vue from 'vue';
import VueCookies from 'vue-cookies';
import Vue2TouchEvents from 'vue2-touch-events';
import VueGtag from 'vue-gtag';
import PortalVue from 'portal-vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { firebaseAuth } from '@/core/firebase';
import '@/core/filter';
import '@/core/icon';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.$cookies.config('300d');
store.dispatch('config/initConfig', { vm: Vue });

Vue.use(Vue2TouchEvents);
Vue.use(PortalVue);
Vue.use(CKEditor);

// Prepare Google Analytics.
const googleAnalyticsCode = process.env.VUE_APP_GOOGLE_ANALYTICS_CODE;
if (googleAnalyticsCode) {
  console.log('Initiating analytics...');
  Vue.use(VueGtag, {
    config: { id: googleAnalyticsCode },
  }, router);
}

let app;
firebaseAuth.onAuthStateChanged((user) => {
  if (!app) {
    store.dispatch('auth/fetchUser', {
      user,
      callback: () => {
        // Bootstrap main app.
        console.log('Initiating app...');
        app = new Vue({
          router,
          store,
          render: (h) => h(App),
        }).$mount('#app');

        // Load Youtube API.
        console.log('Initiating YouTube API...');
        store.dispatch('video/loadYoutube');
      },
    });
  }
});
