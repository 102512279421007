import axios from 'axios';
import { firebaseAuth } from '@/core/firebase';

const errorMessage = 'Unauthorised request.';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

const instanceMedia = axios.create({
  baseURL: process.env.VUE_APP_API_MEDIA_ENDPOINT,
});

async function getToken() {
  const user = firebaseAuth.currentUser;
  // console.log(user);
  if (user == null) {
    // console.log('no user');
    throw new Error('');
  }
  if (!user) {
    throw new Error(errorMessage);
  }
  let token;
  try {
    token = await user.getIdToken(true);
    instance.defaults.headers.common['X-Token'] = token;
  } catch (err) {
    window.location.reload();
    throw err;
  }
  return token;
}

const httpGetPublic = async (path, params) => {
  const response = await instance.get(path, {
    ...params,
    headers: {
      'X-App': 'trainifier',
    },
  });
  return response;
};

const httpGet = async (path, params) => {
  await getToken();
  const response = await instance.get(path, { params });
  return response;
};

const httpPost = async (path, data) => {
  await getToken();
  const response = await instance.post(path, data);
  return response;
};

const httpPostMultipartForm = async (path, data) => {
  await getToken();
  const response = await instance.post(path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

const httpDelete = async (path, data) => {
  await getToken();
  const response = await instance.delete(path, { data });
  return response;
};

const httpPostMedia = async (path, data, onProgressCb = null) => {
  await getToken();
  const response = await instanceMedia.post(path, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-App': process.env.VUE_APP_API_MEDIA_CHALLENGE,
    },
    onUploadProgress: (progress) => {
      if (typeof onProgressCb === 'function') {
        onProgressCb(progress);
      }
    },
  });
  return response;
};

const httpDeleteMedia = async (path, data) => {
  await getToken();
  const response = await instanceMedia.delete(path, {
    headers: {
      'X-App': process.env.VUE_APP_API_MEDIA_CHALLENGE,
    },
    data,
  });
  return response;
};

export {
  httpGetPublic,
  httpGet,
  httpPost,
  httpPostMultipartForm,
  httpDelete,
  httpPostMedia,
  httpDeleteMedia,
};
