const cookieUIMode = 'trnfr_ui_mode';
const cookieUISetPropagation = 'trnfr_ui_set_propagation';
const cookieUIClientListSorter = 'trnfr_ui_client_list_sorter';
const cookieUICalendarViewMode = 'trnfr_ui_calendar_view_mode';
const cookieUIEditorExerciseLookup = 'trnfr_ui_editor_exercise_lookup';

export default {
  namespaced: true,
  state: () => ({
    configUIMode: null,
    configUISetPropagation: null,
    configUIClientListSorter: null,
    configUICalendarViewMode: null,
    configUIEditorExerciseLookup: null,
  }),
  mutations: {
    SET_UI_MODE(state, value) {
      state.configUIMode = value;
    },
    SET_UI_SET_PROPAGATION(state, value) {
      state.configUISetPropagation = value;
    },
    SET_UI_CLIENT_LIST_SORTER(state, value) {
      state.configUIClientListSorter = value;
    },
    SET_UI_CALENDAR_VIEW_MODE(state, value) {
      state.configUICalendarViewMode = value;
    },
    SET_UI_EDITOR_EXERCISE_LOOKUP(state, value) {
      state.configUIEditorExerciseLookup = value;
    },
  },
  actions: {
    initConfig({ dispatch }, payload) {
      const { vm } = payload;

      const uiMode = vm.$cookies.get(cookieUIMode) || 'coach';
      dispatch('setUIMode', { vm, value: uiMode });

      const uiSetPropagation = vm.$cookies.get(cookieUISetPropagation) || 'true';
      dispatch('setUISetPropagation', { vm, value: uiSetPropagation });

      const uiClientListSorter = vm.$cookies.get(cookieUIClientListSorter) || 'nameAsc';
      dispatch('setUIClientListSorter', { vm, value: uiClientListSorter });

      const uiCalendarViewMode = vm.$cookies.get(cookieUICalendarViewMode) || 'full';
      dispatch('setUICalendarViewMode', { vm, value: uiCalendarViewMode });

      const uiEditorExerciseLookup = vm.$cookies.get(cookieUIEditorExerciseLookup) || 'false';
      dispatch('setUIEditorExerciseLookup', { vm, value: uiEditorExerciseLookup });
    },
    setUIMode({ commit }, payload) {
      const { vm, value } = payload;
      vm.$cookies.set(cookieUIMode, value);
      commit('SET_UI_MODE', vm.$cookies.get(cookieUIMode));
    },
    setUISetPropagation({ commit }, payload) {
      const { vm, value } = payload;
      vm.$cookies.set(cookieUISetPropagation, value);
      commit('SET_UI_SET_PROPAGATION', vm.$cookies.get(cookieUISetPropagation));
    },
    setUIClientListSorter({ commit }, payload) {
      const { vm, value } = payload;
      vm.$cookies.set(cookieUIClientListSorter, value);
      commit('SET_UI_CLIENT_LIST_SORTER', vm.$cookies.get(cookieUIClientListSorter));
    },
    setUICalendarViewMode({ commit }, payload) {
      const { vm, value } = payload;
      vm.$cookies.set(cookieUICalendarViewMode, value);
      commit('SET_UI_CALENDAR_VIEW_MODE', vm.$cookies.get(cookieUICalendarViewMode));
    },
    setUIEditorExerciseLookup({ commit }, payload) {
      const { vm, value } = payload;
      vm.$cookies.set(cookieUIEditorExerciseLookup, value);
      commit('SET_UI_EDITOR_EXERCISE_LOOKUP', vm.$cookies.get(cookieUIEditorExerciseLookup));
    },
  },
};
