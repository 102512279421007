import router from '@/router';
import { firebase, firebaseAuth } from '@/core/firebase';
import { httpGet, httpPost } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    authUser: null,
    authFirebaseUser: null,
  }),
  mutations: {
    SET_AUTH_USER(state, user) {
      console.log(`🧛🏻‍♂️ ${user ? `Auth user: ${user.userId}` : 'No auth user'}`);
      state.authUser = user;
    },
    SET_AUTH_FIREBASE_USER(state, user) {
      state.authFirebaseUser = user;
    },
    SET_AUTH_USER_PHOTO(state, photoUrl) {
      if (state.authUser) state.authUser.photoUrl = photoUrl;
    },
  },
  actions: {
    async register({ dispatch }, form) {
      const {
        email,
        password,
        type,
        firstName,
        lastName,
        invitationCode,
        plan,
      } = form;

      // Create user account in firebase.
      const { user } = await firebaseAuth.createUserWithEmailAndPassword(email, password);

      // send user details to stripe, create new subscription, and return customer id.
      // const customerId = await httpPost('/user/subscription', {
      //   email,
      //   plan,
      // });

      // Register account in local database.
      await httpPost('/user', {
        uid: user.uid,
        email,
        type,
        firstName,
        lastName,
        plan,
      });

      // Get user profile.
      await dispatch('fetchUser', { user });
      if (invitationCode) {
        router.push(`/coaching/invitation/${invitationCode}`);
      } else {
        router.push('/');
      }
    },
    async login({ dispatch }, form) {
      const { email, password, redir } = form;
      const { user } = await firebaseAuth.signInWithEmailAndPassword(email, password);
      await dispatch('fetchUser', { user });
      if (router.currentRoute.path === '/login') {
        router.push(redir === '' ? '/' : redir);
      }
    },
    async logout({ commit }) {
      await firebaseAuth.signOut();
      commit('SET_AUTH_USER', null);
      commit('SET_AUTH_FIREBASE_USER', null);
      router.push('/login');
    },
    async fetchUser({ commit, dispatch }, payload) {
      const { user, callback } = payload;
      if (user) {
        try {
          const res = await httpGet('/user');
          commit('SET_AUTH_USER', res.data);
          commit('SET_AUTH_FIREBASE_USER', user);
        } catch (err) {
          dispatch('logout');
        }
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
    },
    async updatePassword({ state }, payload) {
      const { authUser, authFirebaseUser } = state;
      const { oldPassword, newPassword } = payload;
      const credential = await firebase.auth.EmailAuthProvider.credential(
        authUser.email,
        oldPassword,
      );
      await authFirebaseUser.reauthenticateWithCredential(credential);
      await authFirebaseUser.updatePassword(newPassword);
    },
    async updateProfile({ commit }, payload) {
      const res = await httpPost('/user/profile', payload);
      commit('SET_AUTH_USER', res.data);
    },
    async updateUserPhoto({ commit }, photoUrl) {
      commit('SET_AUTH_USER_PHOTO', photoUrl);
    },
  },
  getters: {
    authUserIsAdmin: (state) => state.authUser && state.authUser.type === 'admin',
    authUserIsCoach: (state) => state.authUser && state.authUser.type === 'coach',
    authUserIsAthlete: (state) => state.authUser && state.authUser.type === 'athlete',
    authUserIsBetaTester: (state) => state.authUser && state.authUser.isBetaTester === 1,
    authUserFullname: (state) => {
      const { authUser } = state;
      if (!authUser) return '';
      return `${authUser.firstName} ${authUser.middleName || ''} ${authUser.lastName}`.trim();
    },
  },
};
