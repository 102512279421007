<template>
  <div>
    <AdminDashboard v-if="authUserIsAdmin"></AdminDashboard>
    <CoachDashboard v-else-if="authUserIsCoach && configUIMode === 'coach'"></CoachDashboard>
    <AthleteDashboard v-else></AthleteDashboard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    AdminDashboard: () => import('@/components/dashboard/AdminDashboard'),
    CoachDashboard: () => import('@/components/dashboard/CoachDashboard'),
    AthleteDashboard: () => import('@/components/dashboard/AthleteDashboard'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin', 'authUserIsCoach']),
    ...mapState('config', ['configUIMode']),
  },
};
</script>
