import dayjs from 'dayjs';
import {
  httpGet,
  httpPost,
  httpDelete,
  httpPostMedia,
  httpDeleteMedia,
} from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    libraryItems: [],
    libraryItemInProgress: null,
    libraryUploadTimeStart: null,
    libraryUploadPercentage: 0,
  }),
  mutations: {
    RESET(state) {
      state.libraryItems = [];
      state.libraryItemInProgress = null;
      state.libraryUploadTimeStart = null;
      state.libraryUploadPercentage = 0;
    },
    SET_ITEMS(state, items) {
      state.libraryItems = items;
    },
    BEGIN_PROGRESS(state, item) {
      state.libraryUploadTimeStart = dayjs();
      state.libraryItemInProgress = item;
    },
    UPDATE_PROGRESS(state, percentage) {
      state.libraryUploadPercentage = percentage;
    },
    END_PROGRESS(state) {
      const tm = dayjs().diff(state.libraryUploadTimeStart, 'second');
      console.log(`Finished in: ${tm}s`);
      state.libraryUploadTimeStart = null;
      state.libraryItemInProgress = null;
    },
    ADD_OR_UPDATE_ITEM(state, item) {
      const index = state.libraryItems.findIndex((i) => {
        const { exerciseLibraryId } = i;
        return exerciseLibraryId === item.exerciseLibraryId;
      });
      if (index > -1) {
        state.libraryItems.splice(index, 1, item);
      } else {
        state.libraryItems.push(item);
      }
    },
    DELETE_ITEM(state, item) {
      const index = state.libraryItems.findIndex((i) => {
        const { exerciseLibraryId } = i;
        return exerciseLibraryId === item.exerciseLibraryId;
      });
      if (index > -1) state.libraryItems.splice(index, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async fetchItems({ commit }) {
      const res = await httpGet('/library/list');
      commit('SET_ITEMS', res.data);
    },
    async addItem({ commit }, payload) {
      const res = await httpPost('/library', payload);
      commit('ADD_OR_UPDATE_ITEM', res.data);
      return res;
    },
    async addItemFile({ commit }, payload) {
      const { formData, file } = payload;
      commit('BEGIN_PROGRESS', file);
      const res = await httpPostMedia('/library', formData, (progress) => {
        const { loaded, total } = progress;
        const percent = Math.round((loaded * 100) / total);
        commit('UPDATE_PROGRESS', percent);
      });
      commit('END_PROGRESS');
      return res;
    },
    async deleteItem({ state, commit }, itemId) {
      const item = state.libraryItems.find((i) => i.exerciseLibraryId === itemId);
      if (!item) throw new Error('Item not found');

      // Delete video file (if applicable).
      const { videoSource, videoUrl } = item;
      if (videoSource === 'upload' && videoUrl && videoUrl !== '') {
        await httpDeleteMedia('/library', { key: videoUrl });
      }

      await httpDelete('/library', item);
      commit('DELETE_ITEM', item);
    },
  },
  getters: {
    libraryIsUploading: (state) => state.libraryItemInProgress !== null,
  },
};
