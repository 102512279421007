import { httpGet, httpPost } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    notificationItems: [],
  }),
  mutations: {
    RESET_NOTIFICATION(state) {
      state.notificationItems = [];
    },
    ADD_NOTIFICATION(state, notification) {
      const index = state.notificationItems.findIndex((n) => {
        const { notificationId } = n;
        return notificationId === notification.notificationId;
      });
      if (index === -1) {
        state.notificationItems.push(notification);
      } else {
        state.notificationItems.splice(index, 1, notification);
      }
    },
  },
  actions: {
    resetNotification({ commit }) {
      commit('RESET_NOTIFICATION');
    },
    async fetchNotifications({ commit }) {
      const res = await httpGet('/notification');
      res.data.forEach((notification) => commit('ADD_NOTIFICATION', notification));
    },
    async markAllNotificationsAsRead({ commit, state }) {
      const ids = state.notificationItems.map((n) => n.notificationId);
      const res = await httpPost('/notification', {
        ids,
        isRead: true,
      });
      res.data.forEach((notification) => commit('ADD_NOTIFICATION', notification));
    },
  },
  getters: {
    notificationHasUnreadItems: (state) => {
      const { notificationItems } = state;
      return notificationItems.filter((n) => !!n.isRead === false).length > 0;
    },
  },
};
