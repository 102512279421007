import dayjs from 'dayjs';
import { httpGet } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    feedItems: [],
  }),
  mutations: {
    RESET_FEED(state) {
      state.feedItems = [];
    },
    ADD_SESSION(state, session) {
      const index = state.feedItems.findIndex((s) => s.sessionId === session.sessionId);
      if (index === -1) {
        state.feedItems.push(session);
      } else {
        state.feedItems.splice(index, 1, session);
      }
    },
    SORT_SESSIONS(state, reverseOrder = false) {
      state.feedItems.sort((a, b) => {
        const dateA = dayjs(a.date).unix();
        const dateB = dayjs(b.date).unix();
        if (reverseOrder) {
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
        } else {
          if (dateA > dateB) return 1;
          if (dateA < dateB) return -1;
        }
        return 0;
      });
    },
  },
  actions: {
    resetFeed({ commit }) {
      commit('RESET_FEED');
    },
    async fetchFeed({ commit }, payload) {
      const { dateFrom, dateTo, reverseOrder } = payload;
      const res = await httpGet(`/feed/${dateFrom}/${dateTo}`);
      res.data.forEach((session) => commit('ADD_SESSION', session));
      commit('SORT_SESSIONS', reverseOrder);
    },
  },
};
