import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { httpPost, httpGet } from '@/core/http';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

export default {
  namespaced: true,
  state: () => ({
    targetId: null,
    targetType: null,
    calendarSessions: [],
    calendarSessionIdInEditing: null,
    calendarIsBusy: false,
    selectedSessionIds: [],
  }),
  mutations: {
    RESET_CALENDAR(state) {
      state.targetId = null;
      state.targetType = null;
      state.calendarSessions = [];
      state.calendarSessionIdInEditing = null;
      state.calendarIsBusy = false;
      state.selectedSessionIds = [];
    },
    SET_PERMISSIONS(state, payload) {
      const { targetId, targetType } = payload;
      state.targetId = targetId;
      state.targetType = targetType;
      state.calendarSessions = [];
      state.calendarSessionIdInEditing = null;
      state.calendarIsBusy = false;
      state.selectedSessionIds = [];
    },
    SET_BUSY(state, bool) {
      state.calendarIsBusy = bool;
    },
    SET_SESSION_IN_EDITING(state, session) {
      state.calendarSessionIdInEditing = session;
    },

    /* SELECTION */

    ADD_SELECTION(state, sessionId) {
      const index = state.selectedSessionIds.indexOf(sessionId);
      if (index === -1) state.selectedSessionIds.push(sessionId);

      // Re-sort selections based on date (earlier first).
      state.selectedSessionIds.sort((sessionId1, sessionId2) => {
        const s1 = state.calendarSessions.find((s) => s.sessionId === sessionId1);
        const s2 = state.calendarSessions.find((s) => s.sessionId === sessionId2);
        return dayjs(s1.date).format('X') - dayjs(s2.date).format('X');
      });
    },
    DELETE_SELECTION(state, sessionId) {
      const index = state.selectedSessionIds.indexOf(sessionId);
      if (index > -1) state.selectedSessionIds.splice(index, 1);
    },
    CLEAR_SELECTION(state) {
      state.selectedSessionIds = [];
    },

    /* SESSION MANAGEMENT */

    ADD_SESSION(state, session) {
      const index = state.calendarSessions.findIndex((s) => s.sessionId === session.sessionId);
      if (index > -1) {
        state.calendarSessions.splice(index, 1, session);
      } else {
        state.calendarSessions.push(session);
      }
    },
    MOVE_SESSION(state, payload) {
      const { sessionId, date } = payload;
      const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
      if (session) session.date = date;
    },
    DELETE_SESSION(state, sessionId) {
      const index = state.calendarSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) state.calendarSessions.splice(index, 1);
    },

    /* SESSION EDITOR */

    RESET_SESSION(state, sessionId) {
      const index = state.calendarSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) {
        const newSession = {
          ...state.calendarSessions[index],
          $tm: +dayjs(),
        };
        state.calendarSessions.splice(index, 1, newSession);
      }
    },
    UPDATE_SESSION(state, payload) {
      const { sessionId, ...newPayload } = payload;
      const index = state.calendarSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) {
        const newSession = {
          ...state.calendarSessions[index],
          ...newPayload,
        };
        state.calendarSessions.splice(index, 1, newSession);
      }
    },
    UPDATE_SESSION_ITEM(state, payload) {
      const { sessionId, exercise } = payload;
      const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exercise.exerciseId);
        if (index > -1) {
          session.exercises.splice(index, 1, exercise);
        } else {
          session.exercises.push(exercise);
        }
      }
    },
    UPDATE_SESSION_ITEM_SUPERSET(state, payload) {
      const { sessionId, exerciseId, isSuperset } = payload;
      const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
        if (index > -1) {
          const newExercise = {
            ...session.exercises[index],
            isSuperset,
          };
          session.exercises.splice(index, 1, newExercise);
        }
      }
    },
    UPDATE_SESSION_ITEM_ORDER(state, payload) {
      const { sessionId, exerciseIds } = payload;
      const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const newExercises = [];
        exerciseIds.forEach((exerciseId, exerciseIdx) => {
          const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
          if (index > -1) {
            newExercises.push({
              ...session.exercises[index],
              orderNo: exerciseIdx,
            });
          }
        });
        session.exercises = newExercises;
      }
    },
    DELETE_SESSION_ITEM(state, payload) {
      const { sessionId, exerciseId } = payload;
      const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
        if (index > -1) session.exercises.splice(index, 1);
      }
    },
  },
  actions: {
    resetCalendar({ commit }) {
      commit('RESET_CALENDAR');
    },
    setTargetAndPermissions({ commit }, payload) {
      commit('SET_PERMISSIONS', payload);
    },
    setBusy({ commit }, bool) {
      commit('SET_BUSY', bool);
    },
    setSessionInEditing({ commit }, sessionId) {
      if (sessionId !== null) {
        commit('CLEAR_SELECTION');
      }
      commit('SET_SESSION_IN_EDITING', sessionId);
    },

    /* SELECTION */

    addSelection({ commit, state }, payload) {
      const { sessionId, isMultiple } = payload;
      commit('ADD_SELECTION', sessionId);

      if (isMultiple && state.selectedSessionIds.length > 1) {
        const sessions = state.selectedSessionIds
          .map((id) => state.calendarSessions.find((s) => s.sessionId === id))
          .sort((s1, s2) => dayjs(s1.date).format('X') - dayjs(s2.date).format('X'));
        const d1 = dayjs(sessions[0].date);
        const d2 = dayjs(sessions[sessions.length - 1].date);
        state.calendarSessions.forEach((s) => {
          if (dayjs(s.date).isBetween(d1, d2)) {
            commit('ADD_SELECTION', s.sessionId);
          }
        });
      }
    },
    deleteSelection({ commit }, payload) {
      const { sessionId } = payload;
      commit('DELETE_SELECTION', sessionId);
    },
    clearSelection({ commit }) {
      commit('CLEAR_SELECTION');
    },

    /* SESSION MANAGEMENT */

    updateSessionStatus({ commit }, session) {
      commit('UPDATE_SESSION', session);
    },

    async fetchSessions({ commit, state }, payload) {
      const { dateFrom, dateTo } = payload;
      const { targetId, targetType } = state;
      const res = await httpGet(`/session/range/${dateFrom}/${dateTo}`, { targetId, targetType });
      res.data.forEach((session) => commit('ADD_SESSION', session));
    },
    async createSession({ commit, state }, date) {
      const { targetId, targetType } = state;
      const res = await httpPost('/session', { targetId, targetType, date });
      commit('ADD_SESSION', res.data);
    },
    async moveSession({ commit, state, getters }, payload) {
      const { targetId, targetType } = state;
      const selectedSessions = getters.calendarGetSelectedSessions;
      let newPayload = [];

      // Move all selected sessions.
      if (selectedSessions.length) {
        const { date } = payload;
        const delta = dayjs(date).diff(selectedSessions[0].date, 'day');
        newPayload = selectedSessions.map((s) => {
          const p = {
            sessionId: s.sessionId,
            date: dayjs(s.date).add(delta, 'day').format('YYYY-MM-DD'),
          };
          commit('MOVE_SESSION', p);
          return p;
        });

      // Move a single session.
      } else {
        commit('MOVE_SESSION', payload);
        newPayload = [payload];
      }

      await httpPost('/session/move', {
        targetId,
        targetType,
        moves: newPayload,
      });
      commit('CLEAR_SELECTION');
    },
    async pasteSessions({ commit, state }, payload) {
      const { targetId, targetType } = state;
      const { sessions, date } = payload;
      const newPayload = [];
      let delta = null;

      sessions.forEach((session) => {
        const { sessionId } = session;
        if (delta === null) {
          delta = dayjs(date).diff(session.date, 'day');
        }
        newPayload.push({
          sessionId,
          date: dayjs(session.date).add(delta, 'day').format('YYYY-MM-DD'),
        });
      });

      if (newPayload.length) {
        const res = await httpPost('/session/copy', { targetId, targetType, sessions: newPayload });
        res.data.forEach((session) => commit('ADD_SESSION', session));
      }
    },
    async deleteSessions({ commit, state }) {
      const { targetId, targetType } = state;
      if (state.selectedSessionIds.length) {
        const res = await httpPost('/session/delete', {
          targetId,
          targetType,
          sessionIds: state.selectedSessionIds,
        });
        res.data.forEach((sessionId) => commit('DELETE_SESSION', sessionId));
      }
    },
    async assignProgram({ commit }, payload) {
      const { programId, userId, date } = payload;
      const res = await httpPost(`/program/${programId}/assign`, { userId, date });
      res.data.forEach((session) => commit('ADD_SESSION', session));
    },

    /* SESSION EDITOR */

    resetSession({ commit }, sessionId) {
      commit('RESET_SESSION', sessionId);
    },
    async updateSession({ commit }, payload) {
      const { sessionId, name, status } = payload;
      await httpPost(`/session/${sessionId}`, { name, status });
      commit('UPDATE_SESSION', payload);
    },
    async addSessionItem({ commit }, payload) {
      const { sessionId } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise`, payload);
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async updateSessionItem({ commit }, payload) {
      const { sessionId, exerciseId, ...newPayload } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise/${exerciseId}`, newPayload);
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async deleteSessionItem({ commit }, payload) {
      const { sessionId, exerciseId } = payload;
      await httpPost(`/session/${sessionId}/exercise/${exerciseId}/delete`);
      commit('DELETE_SESSION_ITEM', payload);
    },
    async supersetSessionItem({ commit }, payload) {
      // NOTE: Update UI state first before sending API request.
      const { sessionId, exerciseId, isSuperset } = payload;
      commit('UPDATE_SESSION_ITEM_SUPERSET', payload);
      const res = await httpPost(`/session/${sessionId}/exercise/${exerciseId}`, { isSuperset });
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async reorderSessionItems({ commit }, payload) {
      // NOTE: Update UI state first before sending API request.
      const { sessionId, exerciseIds } = payload;
      commit('UPDATE_SESSION_ITEM_ORDER', payload);
      const res = await httpPost(`/session/${sessionId}/exercise/order`, { exerciseIds });
      res.data.forEach((exercise) => {
        commit('UPDATE_SESSION_ITEM', { sessionId, exercise });
      });
    },
  },
  getters: {
    calendarGetSessionIsSelected: (state) => (sessionId) => {
      const index = state.selectedSessionIds.findIndex((sId) => sId === sessionId);
      return index > -1;
    },
    calendarGetSelectedSessions: (state) => {
      const sessions = [];
      state.selectedSessionIds.forEach((sessionId) => {
        const session = state.calendarSessions.find((s) => s.sessionId === sessionId);
        if (session) sessions.push(session);
      });
      return sessions;
    },
    calendarGetSession: (state) => (sessionId) => {
      const sessions = state.calendarSessions;
      return sessions.find((s) => s.sessionId === sessionId);
    },
  },
};
