<template>
  <div id="app" :class="{ 'has-tabbar': hasTabBar }">
    <SystemMessage></SystemMessage>

    <NavBar></NavBar>

    <router-view :key="$route.path"></router-view>

    <TabBar></TabBar>

    <portal-target name="modal" multiple></portal-target>

    <portal-target name="toast" multiple class="toast-container"></portal-target>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    SystemMessage: () => import('@/components/message/SystemMessage'),
    NavBar: () => import('@/components/bar/NavBar'),
    TabBar: () => import('@/components/bar/TabBar'),
  },
  computed: {
    ...mapState('config', ['configUIMode']),
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsCoach', 'authUserIsAthlete']),
    hasTabBar() {
      return this.authUserIsAthlete || (this.authUserIsCoach && this.configUIMode === 'athlete');
    },
  },
  watch: {
    authUser() {
      this.checkNotifications();
    },
  },
  methods: {
    checkNotifications() {
      this.$store.dispatch('notification/resetNotification');
      const { authUser, authUserIsCoach } = this;
      if (authUser && authUserIsCoach) {
        this.getNotifications();
      }
    },
    async getNotifications() {
      try {
        await this.$store.dispatch('notification/fetchNotifications');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
  },
  mounted() {
    this.checkNotifications();
  },
};
</script>

<style lang="scss" scoped>
.has-tabbar {
  padding-bottom: 5rem;
}
</style>
