import Vue from 'vue';
import Vuex from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import auth from './modules/auth';
import calendar from './modules/calendar';
import client from './modules/client';
import comment from './modules/comment';
import config from './modules/config';
import feed from './modules/feed';
import library from './modules/library';
import metric from './modules/metric';
import notification from './modules/notification';
import program from './modules/program';
import session from './modules/session';
import video from './modules/video';
import workout from './modules/workout';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    systemMessages: [],
    systemErrors: [],
  },
  mutations: {
    ADD_SYSTEM_MESSAGE(state, message) {
      state.systemMessages.push({
        id: uuidv4(),
        message,
      });
    },
    REMOVE_SYSTEM_MESSAGE(state, message) {
      const index = state.systemMessages.findIndex((e) => e.id === message.id);
      if (index > -1) state.systemMessages.splice(index, 1);
    },
    ADD_SYSTEM_ERROR(state, error) {
      state.systemErrors.push({
        id: uuidv4(),
        error,
      });
    },
    REMOVE_SYSTEM_ERROR(state, error) {
      const index = state.systemErrors.findIndex((e) => e.id === error.id);
      if (index > -1) state.systemErrors.splice(index, 1);
    },
  },
  actions: {
    addSystemMessage({ commit }, message) {
      commit('ADD_SYSTEM_MESSAGE', message);
    },
    removeSystemMessage({ commit }, message) {
      commit('REMOVE_SYSTEM_MESSAGE', message);
    },
    addSystemError({ commit }, error) {
      commit('ADD_SYSTEM_ERROR', error);
    },
    removeSystemError({ commit }, error) {
      commit('REMOVE_SYSTEM_ERROR', error);
    },
  },
  modules: {
    auth,
    calendar,
    client,
    comment,
    config,
    feed,
    library,
    metric,
    notification,
    program,
    session,
    video,
    workout,
  },
});
