import { httpGet, httpPost, httpDelete } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    metricItems: [],
    metricGroups: [],
  }),
  mutations: {
    RESET(state) {
      state.metricItems = [];
      state.metricGroups = [];
    },
    SET_ITEMS(state, items) {
      state.metricItems = items;
    },
    SET_GROUPS(state, groups) {
      state.metricGroups = groups;
    },
    ADD_OR_UPDATE_ITEM(state, item) {
      const index = state.metricItems.findIndex((i) => {
        const { metricId } = i;
        return metricId === item.metricId;
      });
      if (index > -1) {
        state.metricItems.splice(index, 1, item);
      } else {
        state.metricItems.push(item);
      }
    },
    ADD_OR_UPDATE_GROUP(state, group) {
      const index = state.metricGroups.findIndex((i) => {
        const { metricGroupId } = i;
        return metricGroupId === group.metricGroupId;
      });
      if (index > -1) {
        state.metricGroups.splice(index, 1, group);
      } else {
        state.metricGroups.push(group);
      }
    },
    DELETE_ITEM(state, item) {
      const index = state.metricItems.findIndex((i) => {
        const { metricId } = i;
        return metricId === item.metricId;
      });
      if (index > -1) state.metricItems.splice(index, 1);
    },
    DELETE_GROUP(state, group) {
      const index = state.metricGroups.findIndex((i) => {
        const { metricGroupId } = i;
        return metricGroupId === group.metricGroupId;
      });
      if (index > -1) state.metricGroups.splice(index, 1);
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    async fetchItems({ commit }) {
      commit('SET_ITEMS', []);
      const res = await httpGet('/metric/list');
      const { groups, metrics } = res.data;
      commit('SET_ITEMS', metrics);
      commit('SET_GROUPS', groups);
    },
    async addItem({ commit }, payload) {
      const res = await httpPost('/metric', payload);
      commit('ADD_OR_UPDATE_ITEM', res.data);
    },
    async addGroup({ commit }, payload) {
      const res = await httpPost('/metric/group', payload);
      commit('ADD_OR_UPDATE_GROUP', res.data);
    },
    async deleteItem({ state, commit }, itemId) {
      const item = state.metricItems.find((i) => i.metricId === itemId);
      if (!item) throw new Error('Item not found');
      await httpDelete('/metric', item);
      commit('DELETE_ITEM', item);
    },
    async deleteGroup({ state, commit }, groupId) {
      const group = state.metricGroups.find((g) => g.metricGroupId === groupId);
      if (!group) throw new Error('Group not found');
      await httpDelete('/metric/group', group);
      commit('DELETE_GROUP', group);
    },
    async moveItem({ commit }, payload) {
      const res = await httpPost('/metric/move', payload);
      res.data.forEach((item) => commit('ADD_OR_UPDATE_ITEM', item));
    },
  },
  getters: {
    metricItemsOnRoot: (state) => state.metricItems.filter((i) => i.metricGroupId === null),
    metricItemsOnGroup: (state) => (groupId) => {
      const { metricItems } = state;
      return metricItems.filter((i) => i.metricGroupId === groupId);
    },
    metricItemById: (state) => (metricId) => state.metricItems.find((m) => m.metricId === metricId),
  },
};
