export default {
  namespaced: true,
  state: () => ({
    YT: null,
    activeVideoId: null,
  }),
  mutations: {
    SET_YOUTUBE(state, youtube) {
      console.log('🧛🏻‍♂️ Youtube is ready...');
      state.YT = youtube;
    },
    SET_ACTIVE_VIDEO_ID(state, videoId) {
      state.activeVideoId = videoId;
    },
  },
  actions: {
    loadYoutube({ commit }) {
      window.onYouTubeIframeAPIReady = () => {
        commit('SET_YOUTUBE', window.YT);
      };

      const script = document.createElement('script');
      script.defer = true;
      script.async = true;
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    },
    setActiveVideoId({ commit }, videoId) {
      commit('SET_ACTIVE_VIDEO_ID', videoId);
    },
  },
};
