import Vue from 'vue';
import he from 'he';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(AdvancedFormat);

Vue.filter('dateReadable', (dateString) => dayjs(dateString).format('lll'));

Vue.filter('utcDateReadable', (dateString) => dayjs.utc(dateString).local().format('lll'));

Vue.filter('dateReadableShort', (dateString) => dayjs(dateString).format('MMM, D YYYY'));

Vue.filter('utcDateReadableShort', (dateString) => dayjs.utc(dateString).local().format('MMM, D YYYY'));

Vue.filter('dateReadableShortArray', (dateString) => {
  const d = dayjs(dateString);
  return [
    d.format('MMM, D'),
    d.format('YYYY'),
  ];
});

Vue.filter('dateReadableLong', (dateString) => dayjs(dateString).format('dddd, D MMMM'));

Vue.filter('utcDateReadableLong', (dateString) => dayjs.utc(dateString).local().format('dddd, D MMMM'));

Vue.filter('dateTimeReadableShort', (dateString) => dayjs(dateString).format('MMM, D YYYY HH:mm'));

Vue.filter('dateRelative', (dateString) => dayjs(dateString).fromNow());

Vue.filter('utcDateRelative', (dateString) => dayjs.utc(dateString).local().fromNow());

Vue.filter('dateForStat', (dateString) => dayjs(dateString).format('DD/MM/YY'));

Vue.filter('timeReadable', (dateString) => dayjs(dateString).format('HH:mm'));

Vue.filter('utcTimeReadable', (dateString) => dayjs.utc(dateString).local().format('HH:mm'));

Vue.filter('sessionNameReadable', (session) => {
  if (session.name && session.name !== '') return session.name;
  return `Session ${dayjs(session.date).format('D MMM')}`;
});

Vue.filter('nl2br', (str) => {
  const tag = '<br>';
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${tag}$2`);
});

Vue.filter('unitReadable', (str) => {
  if (str === 'percent') return '%';
  return str;
});

Vue.filter('quotaReadable', (val) => (val < 0 ? 'Unlimited' : val));

Vue.filter('errorReadable', (error) => {
  const { response } = error;
  if (response && response.data && response.data.message) {
    return response.data.message;
  }
  return error.toString();
});

Vue.filter('sanitized', (str) => he.decode(str));

Vue.filter('exerciseSetParsed', (exercise) => {
  const { exerciseSet } = exercise;
  try {
    return exerciseSet ? JSON.parse(exerciseSet) : [];
  } catch {
    return [];
  }
});

Vue.filter('exerciseResourceParsed', (exercise) => {
  const { resource } = exercise;
  try {
    return resource ? JSON.parse(resource) : [];
  } catch {
    return [];
  }
});

Vue.filter('arrayStringParsed', (str) => {
  try {
    return str ? JSON.parse(str) : [];
  } catch {
    return [];
  }
});

Vue.filter('round', (value, decimals) => {
  const v = !value ? 0 : value;
  const d = !decimals ? 0 : decimals;
  return Math.round(v * (10 ** d)) / (10 ** d);
});

Vue.filter('dailyMealPlan', (value) => {
  if (value > 0) return 'Surplus';
  if (value < 0) return 'Deficit';
  return 'Maintenance';
});

Vue.filter('byteSize', (bytes, decimals = 2) => {
  if (bytes === 0) return '0Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
});

Vue.filter('nameInitial', (str) => {
  if (!str || !str.length) return '';
  return str.length > 1 ? `${str[0]}.` : str;
});

Vue.filter('fullname', (user) => {
  const { firstName, middleName, lastName } = user;
  return [firstName, middleName, lastName].filter((val) => val && val !== '').join(' ');
});

Vue.filter('basename', (path) => {
  const chunks = path.split('/');
  if (chunks.length) return chunks[chunks.length - 1];
  return path;
});

Vue.filter('numberReadable', (n) => n.toLocaleString());

Vue.filter('metricTargetReadable', (metric) => {
  const { target, unit } = metric;
  if (!target || parseInt(target, 10) <= 0) return 'not set';
  return `${target}${unit}`;
});
