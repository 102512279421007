import dayjs from 'dayjs';
import { httpGet, httpPost } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    workoutSession: null,
    workoutExerciseStatuses: [],
    workoutStartTime: null,
    workoutFinishTime: null,
  }),
  mutations: {
    SET_SESSION(state, session) {
      state.workoutSession = session;
    },
    SET_EXERCISE_STATUSES(state, statuses) {
      state.workoutExerciseStatuses = statuses;
    },
    UPDATE_EXERCISE_STATUS(state, status) {
      const index = state.workoutExerciseStatuses.findIndex((exerciseStatus) => {
        const { exerciseId } = exerciseStatus;
        return exerciseId === status.exerciseId;
      });
      if (index > -1) {
        state.workoutExerciseStatuses.splice(index, 1, status);
      } else {
        state.workoutExerciseStatuses.push(status);
      }
    },
    SET_PROGRESS(state, payload) {
      const { startedAt, finishedAt } = payload;
      state.workoutStartTime = startedAt;
      state.workoutFinishTime = finishedAt;
    },
  },
  actions: {
    resetWorkout({ commit }) {
      commit('SET_SESSION', null);
      commit('SET_EXERCISE_STATUSES', []);
      commit('SET_PROGRESS', {
        startedAt: null,
        finishedAt: null,
      });
    },
    async fetchWorkoutSession({ commit }, payload) {
      const { sessionId, athleteId, action } = payload;
      const resSession = await httpGet(`/session/${sessionId}`, { action, athleteId });
      const resSessionProgress = await httpGet(`/session/${sessionId}/progress`, { athleteId });
      const session = resSession.data;
      commit('SET_SESSION', session);
      commit('SET_EXERCISE_STATUSES', session.exerciseStatuses);
      commit('SET_PROGRESS', resSessionProgress.data);
    },
    async startWorkout({ commit }, payload) {
      const { sessionId, athleteId } = payload;
      const res = await httpPost(`/session/${sessionId}/progress`, { athleteId, key: 'start' });
      commit('SET_PROGRESS', res.data);
    },
    async finishWorkout({ commit }, payload) {
      const { sessionId, athleteId } = payload;
      const res = await httpPost(`/session/${sessionId}/progress`, { athleteId, key: 'finish' });
      commit('SET_PROGRESS', res.data);
    },
    async setExerciseCompletion({ commit }, payload) {
      const { sessionId } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise/status`, payload);
      commit('UPDATE_EXERCISE_STATUS', res.data);
    },
    async setExerciseNote({ commit }, payload) {
      const { sessionId } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise/note`, payload);
      commit('UPDATE_EXERCISE_STATUS', res.data);
    },
  },
  getters: {
    workoutIsStarted: (state) => state.workoutStartTime !== null,
    workoutIsFinished: (state) => {
      const { workoutStartTime, workoutFinishTime } = state;
      return workoutStartTime !== null && workoutFinishTime !== null;
    },
    workoutCompletionDuration: (state, getters) => {
      if (!getters.workoutIsFinished) return null;
      const { workoutStartTime, workoutFinishTime } = state;
      const s = dayjs(workoutFinishTime).diff(dayjs(workoutStartTime), 's');
      if (s < 86400) return new Date(s * 1000).toISOString().substr(11, 8);
      return [
        Math.floor(s / 60 / 60 / 24),
        Math.floor(s / 60 / 60) % 24,
        Math.floor(s / 60) % 60,
        s % 60,
      ].map((val) => (val < 10 ? `0${val}` : `${val}`)).join(':');
    },
    workoutExercisesByGroup: (state) => {
      if (!state.workoutSession) return [];
      let currentSection = null;
      let previousItem = null;
      const exercises = [];
      state.workoutSession.exercises.forEach((exercise) => {
        if (exercise.type === 'section') {
          currentSection = exercise;
        }
        if (exercise.type === 'exercise' || exercise.type === 'circuit') {
          if (exercise.isSuperset && previousItem && previousItem.type !== 'section') {
            exercises[exercises.length - 1].exercises.push(exercise);
          } else {
            exercises.push({
              section: currentSection,
              exercises: [exercise],
            });
          }
        }
        previousItem = exercise;
      });
      return exercises;
    },
    workoutUnmarkedExercises: (state) => {
      if (!state.workoutSession) return [];
      const exerciseIds = [];
      state.workoutSession.exercises.forEach((exercise) => {
        const { exerciseId, type } = exercise;
        if (type !== 'section') {
          const markedExercise = state.workoutExerciseStatuses.find(
            (exerciseStatus) => exerciseStatus.exerciseId === exerciseId,
          );
          if (!markedExercise || markedExercise.isCompleted === null) {
            exerciseIds.push(exerciseId);
          }
        }
      });
      return exerciseIds;
    },
  },
};
