import { deepCopy } from '@/core/util';

export default {
  namespaced: true,
  state: () => ({
    sessionClipboardItems: [],
  }),
  mutations: {
    RESET(state) {
      state.sessionClipboardItems = [];
    },
    CLEAR_CLIPBOARD(state) {
      state.sessionClipboardItems = [];
    },
    ADD_SESSIONS_TO_CLIPBOARD(state, sessions) {
      sessions.forEach((s) => {
        const sessionCopy = deepCopy(s);
        state.sessionClipboardItems.push(sessionCopy);
      });
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    addSessionsToClipboard({ commit }, sessions) {
      commit('CLEAR_CLIPBOARD');
      commit('ADD_SESSIONS_TO_CLIPBOARD', sessions);
    },
  },
};
