import { httpGet, httpPost } from '@/core/http';

export default {
  namespaced: true,
  state: () => ({
    commentIsLoaded: false,
    commentItems: [],
  }),
  mutations: {
    RESET_COMMENT(state) {
      state.commentIsLoaded = false;
      state.commentItems = [];
    },
    SET_COMMENT_IS_LOADED(state, bool) {
      state.commentIsLoaded = bool;
    },
    PREPEND_COMMENT(state, newComment) {
      const index = state.commentItems.findIndex((comment) => {
        const { commentId } = comment;
        return commentId === newComment.commentId;
      });
      if (index > -1) {
        state.commentItems.splice(index, 1, newComment);
      } else {
        state.commentItems.unshift(newComment);
      }
    },
    APPEND_COMMENT(state, newComment) {
      const index = state.commentItems.findIndex((comment) => {
        const { commentId } = comment;
        return commentId === newComment.commentId;
      });
      if (index > -1) {
        state.commentItems.splice(index, 1, newComment);
      } else {
        state.commentItems.push(newComment);
      }
    },
  },
  actions: {
    resetComment({ commit }) {
      commit('RESET_COMMENT');
    },
    async fetchComment({ commit }, payload) {
      const res = await httpGet('/comment', payload);
      res.data.forEach((comment) => {
        commit('PREPEND_COMMENT', comment);
      });
      commit('SET_COMMENT_IS_LOADED', true);
    },
    async addComment({ commit, state, dispatch }, payload) {
      const res = await httpPost('/comment', payload);
      if (!state.commentIsLoaded) {
        await dispatch('fetchComment', payload);
      }
      commit('APPEND_COMMENT', res.data);
    },
  },
};
