import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { httpGet, httpPost } from '@/core/http';

dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

export default {
  namespaced: true,
  state: () => ({
    program: null,
    programSessions: [],
    programSessionIdInEditing: null,
    programIsBusy: false,
    selectedSessionIds: [],
  }),
  mutations: {
    RESET(state) {
      state.program = null;
      state.programSessions = [];
      state.programSessionIdInEditing = null;
      state.programIsBusy = false;
      state.selectedSessionIds = [];
    },
    SET_PROGRAM(state, program) {
      state.program = program;
    },
    SET_BUSY(state, bool) {
      state.programIsBusy = bool;
    },
    SET_SESSION_IN_EDITING(state, sessionId) {
      state.programSessionIdInEditing = sessionId;
    },

    /* SELECTION */

    ADD_SELECTION(state, sessionId) {
      const index = state.selectedSessionIds.indexOf(sessionId);
      if (index === -1) state.selectedSessionIds.push(sessionId);

      // Re-sort selections based on date (earlier first).
      state.selectedSessionIds.sort((sessionId1, sessionId2) => {
        const s1 = state.programSessions.find((s) => s.sessionId === sessionId1);
        const s2 = state.programSessions.find((s) => s.sessionId === sessionId2);
        return dayjs(s1.date).format('X') - dayjs(s2.date).format('X');
      });
    },
    DELETE_SELECTION(state, sessionId) {
      const index = state.selectedSessionIds.indexOf(sessionId);
      if (index > -1) state.selectedSessionIds.splice(index, 1);
    },
    CLEAR_SELECTION(state) {
      state.selectedSessionIds = [];
    },

    /* SESSION MANAGEMENT */

    ADD_SESSION(state, session) {
      const index = state.programSessions.findIndex((s) => s.sessionId === session.sessionId);
      if (index > -1) {
        state.programSessions.splice(index, 1, session);
      } else {
        state.programSessions.push(session);
      }
    },
    MOVE_SESSION(state, payload) {
      const { sessionId, date } = payload;
      const session = state.programSessions.find((s) => s.sessionId === sessionId);
      if (session) session.date = date;
    },
    DELETE_SESSION(state, sessionId) {
      const index = state.programSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) state.programSessions.splice(index, 1);
    },

    /* SESSION EDITOR */

    RESET_SESSION(state, sessionId) {
      const index = state.programSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) {
        const newSession = {
          ...state.programSessions[index],
          $tm: +dayjs(),
        };
        state.programSessions.splice(index, 1, newSession);
      }
    },
    UPDATE_SESSION(state, payload) {
      const { sessionId, name, status } = payload;
      const index = state.programSessions.findIndex((s) => s.sessionId === sessionId);
      if (index > -1) {
        const newSession = {
          ...state.programSessions[index],
          name,
          status,
        };
        state.programSessions.splice(index, 1, newSession);
      }
    },
    UPDATE_SESSION_ITEM(state, payload) {
      const { sessionId, exercise } = payload;
      const session = state.programSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exercise.exerciseId);
        if (index > -1) {
          session.exercises.splice(index, 1, exercise);
        } else {
          session.exercises.push(exercise);
        }
      }
    },
    UPDATE_SESSION_ITEM_SUPERSET(state, payload) {
      const { sessionId, exerciseId, isSuperset } = payload;
      const session = state.programSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
        if (index > -1) {
          const newExercise = {
            ...session.exercises[index],
            isSuperset,
          };
          session.exercises.splice(index, 1, newExercise);
        }
      }
    },
    UPDATE_SESSION_ITEM_ORDER(state, payload) {
      const { sessionId, exerciseIds } = payload;
      const session = state.programSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const newExercises = [];
        exerciseIds.forEach((exerciseId, exerciseIdx) => {
          const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
          if (index > -1) {
            newExercises.push({
              ...session.exercises[index],
              orderNo: exerciseIdx,
            });
          }
        });
        session.exercises = newExercises;
      }
    },
    DELETE_SESSION_ITEM(state, payload) {
      const { sessionId, exerciseId } = payload;
      const session = state.programSessions.find((s) => s.sessionId === sessionId);
      if (session) {
        const index = session.exercises.findIndex((e) => e.exerciseId === exerciseId);
        if (index > -1) session.exercises.splice(index, 1);
      }
    },
  },
  actions: {
    reset({ commit }) {
      commit('RESET');
    },
    setBusy({ commit }, bool) {
      commit('SET_BUSY', bool);
    },
    setSessionInEditing({ commit }, sessionId) {
      if (sessionId !== null) {
        commit('CLEAR_SELECTION');
      }
      commit('SET_SESSION_IN_EDITING', sessionId);
    },

    /* SELECTION */

    addSelection({ commit, state }, payload) {
      const { sessionId, isMultiple } = payload;
      commit('ADD_SELECTION', sessionId);

      if (isMultiple && state.selectedSessionIds.length > 1) {
        const sessions = state.selectedSessionIds
          .map((id) => state.programSessions.find((s) => s.sessionId === id))
          .sort((s1, s2) => dayjs(s1.date).format('X') - dayjs(s2.date).format('X'));
        const d1 = dayjs(sessions[0].date);
        const d2 = dayjs(sessions[sessions.length - 1].date);
        state.programSessions.forEach((s) => {
          if (dayjs(s.date).isBetween(d1, d2)) {
            commit('ADD_SELECTION', s.sessionId);
          }
        });
      }
    },
    deleteSelection({ commit }, payload) {
      const { sessionId } = payload;
      commit('DELETE_SELECTION', sessionId);
    },
    clearSelection({ commit }) {
      commit('CLEAR_SELECTION');
    },

    /* SESSION MANAGEMENT */

    async fetchProgram({ commit }, payload) {
      const { programId, authorId } = payload;
      const res = await httpGet(`/program/${programId}/author/${authorId}/`);
      commit('SET_PROGRAM', res.data);
    },
    async updateProgram({ commit }, payload) {
      const { programId, authorId, name } = payload;
      const res = await httpPost(`/program/${programId}/author/${authorId}`, { name });
      commit('SET_PROGRAM', res.data);
    },
    async fetchSessionsForProgram({ commit }, payload) {
      const { programId, authorId } = payload;
      const res = await httpGet(`/program/${programId}/author/${authorId}/sessions`);
      res.data.forEach((session) => {
        commit('ADD_SESSION', session);
      });
    },
    async createSessionForProgram({ commit }, payload) {
      const { programId, authorId, date } = payload;
      const res = await httpPost(`/program/${programId}/author/${authorId}/session`, { date });
      commit('ADD_SESSION', res.data);
    },
    async moveSession({ commit, getters }, payload) {
      const selectedSessions = getters.programGetSelectedSessions;
      let newPayload = [];

      // Move all selected sessions.
      if (selectedSessions.length) {
        const { date } = payload;
        const delta = dayjs(date).diff(selectedSessions[0].date, 'day');
        newPayload = selectedSessions.map((s) => {
          const p = {
            sessionId: s.sessionId,
            date: dayjs(s.date).add(delta, 'day').format('YYYY-MM-DD'),
          };
          commit('MOVE_SESSION', p);
          return p;
        });

      // Move a single session.
      } else {
        commit('MOVE_SESSION', payload);
        newPayload = [payload];
      }

      await httpPost('/session/move', {
        targetId: null,
        targetType: 'template',
        moves: newPayload,
      });
      commit('CLEAR_SELECTION');
    },
    async pasteSessions({ commit }, payload) {
      const { programId, day, sessions } = payload;
      const newPayload = [];
      let delta = null;

      sessions.forEach((session) => {
        if (delta === null) delta = day.diff(session.date, 'day');
        newPayload.push({
          sessionId: session.sessionId,
          date: dayjs(session.date).add(delta, 'day').format('YYYY-MM-DD'),
        });
      });

      if (newPayload.length) {
        const res = await httpPost('/session/copy', {
          targetType: 'template',
          targetId: programId,
          sessions: newPayload,
        });
        res.data.forEach((session) => commit('ADD_SESSION', session));
      }
    },
    async deleteSessions({ commit, state }) {
      if (state.selectedSessionIds.length) {
        const res = await httpPost('/session/delete', {
          targetId: null,
          targetType: 'template',
          sessionIds: state.selectedSessionIds,
        });
        res.data.forEach((sessionId) => commit('DELETE_SESSION', sessionId));
      }
    },

    /* SESSION EDITOR */

    resetSession({ commit }, sessionId) {
      commit('RESET_SESSION', sessionId);
    },
    async updateSession({ commit }, payload) {
      const { sessionId, name, status } = payload;
      await httpPost(`/session/${sessionId}`, { name, status });
      commit('UPDATE_SESSION', payload);
    },
    async addSessionItem({ commit }, payload) {
      const { sessionId } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise`, payload);
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async updateSessionItem({ commit }, payload) {
      const { sessionId, exerciseId, ...newPayload } = payload;
      const res = await httpPost(`/session/${sessionId}/exercise/${exerciseId}`, newPayload);
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async deleteSessionItem({ commit }, payload) {
      const { sessionId, exerciseId } = payload;
      await httpPost(`/session/${sessionId}/exercise/${exerciseId}/delete`);
      commit('DELETE_SESSION_ITEM', payload);
    },
    async supersetSessionItem({ commit }, payload) {
      // NOTE: Update UI state first before sending API request.
      const { sessionId, exerciseId, isSuperset } = payload;
      commit('UPDATE_SESSION_ITEM_SUPERSET', payload);
      const res = await httpPost(`/session/${sessionId}/exercise/${exerciseId}`, { isSuperset });
      commit('UPDATE_SESSION_ITEM', { sessionId, exercise: res.data });
    },
    async reorderSessionItems({ commit }, payload) {
      // NOTE: Update UI state first before sending API request.
      const { sessionId, exerciseIds } = payload;
      commit('UPDATE_SESSION_ITEM_ORDER', payload);
      const res = await httpPost(`/session/${sessionId}/exercise/order`, { exerciseIds });
      res.data.forEach((exercise) => {
        commit('UPDATE_SESSION_ITEM', { sessionId, exercise });
      });
    },
  },
  getters: {
    programGetSessionIsSelected: (state) => (sessionId) => {
      const index = state.selectedSessionIds.findIndex((sId) => sId === sessionId);
      return index > -1;
    },
    programGetSelectedSessions: (state) => {
      const sessions = [];
      state.selectedSessionIds.forEach((sessionId) => {
        const session = state.programSessions.find((s) => s.sessionId === sessionId);
        if (session) {
          sessions.push(session);
        }
      });
      return sessions;
    },
    programGetSession: (state) => (sessionId) => {
      const { programSessions } = state;
      return programSessions.find((s) => s.sessionId === sessionId);
    },
    programGetSessionsSortedByDate: (state) => {
      const sessions = state.programSessions;
      sessions.sort((s1, s2) => dayjs(s1.date).format('X') - dayjs(s2.date).format('X'));
      return sessions;
    },
  },
};
